@import url(https://fonts.googleapis.com/css?family=Asap|Exo:800&display=swap);
html {
  font-size: 10px;
  scroll-behavior: smooth; }

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  --Dark: hsl(0, 0%, 13%);
  --Neutral: #898989;
  --Light: hsl(0, 0%, 87%);
  --Blood: #7F0011;
  --Sand: #FFAA55;
  --Sky: #89FFFF;
  --Large-text: 10rem;
  --Medium-text: 7rem;
  --Small-text: 1.6rem;
  font-family: 'Asap', sans-serif; }

body {
  background-color: var(--Dark); }

.App {
  min-height: 100vh;
  color: var(--Light);
  display: grid;
  grid-template-columns: 1fr 3.25fr; }
  .App section {
    padding: 7rem 0; }
  .App img {
    border-radius: 1rem; }

.Separator {
  width: 100%;
  height: 50rem;
  grid-column: 1 / 3;
  background-size: cover;
  background-color: transparent;
  background-attachment: fixed;
  background-position: center;
  overflow: hidden; }
  .Separator:last-child {
    background-position: top;
    height: 65rem; }

.Nav {
  position: -webkit-sticky;
  position: sticky;
  top: 7rem;
  left: 5.2vw;
  height: 85vh;
  width: 18vw;
  grid-column: 1 / 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  color: var(--Neutral);
  font-size: 3rem;
  -webkit-transition: all .2s ease-out;
  transition: all .2s ease-out; }
  .Nav-bar {
    display: flex;
    flex-direction: column;
    width: 200px; }
  .Nav-logo {
    width: 7rem;
    fill: var(--Neutral); }
  .Nav > * {
    margin-bottom: 1rem; }

a {
  text-decoration: none;
  color: inherit;
  font-family: 'Asap', sans-serif; }

.About {
  display: flex;
  justify-content: flex-start;
  min-height: 100vh;
  width: 65vw;
  padding: 7rem 5.2vw 7rem 0;
  grid-column: 2 / 3; }
  .About-content {
    display: flex;
    flex-direction: column;
    font-size: var(--Large-text); }
  .About-paragraph {
    font-family: 'Exo', sans-serif;
    text-transform: uppercase;
    line-height: var(--Large-text);
    margin-bottom: 3rem; }
    .About-paragraph span {
      background: -webkit-gradient(linear, right top, left top, from(var(--Blood)), color-stop(var(--Sand)), to(var(--Sky)));
      background: -webkit-linear-gradient(right, var(--Blood), var(--Sand), var(--Sky));
      background: linear-gradient(to left, var(--Blood), var(--Sand), var(--Sky));
      background-size: cover;
      -webkit-animation: Swirl 3s ease infinite;
      animation: Swirl 3s ease infinite;
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
      font-family: inherit; }
    .About-paragraph a {
      font-family: inherit; }
    .About-paragraph a:hover {
      color: var(--Sand); }
  .About-Button {
    height: 5rem;
    width: 5rem;
    fill: var(--Neutral);
    margin-bottom: 3rem; }
    .About-Button:hover {
      -webkit-animation: spin 1s linear infinite forwards;
              animation: spin 1s linear infinite forwards; }
  .About-DropDown {
    margin-bottom: 5rem;
    width: 65vw;
    font-size: var(--Small-text);
    line-height: 2.6rem;
    display: flex;
    justify-content: space-between;
    -webkit-animation: FadeIn .2s ease-out;
            animation: FadeIn .2s ease-out; }
    .About-DropDown > * {
      margin-right: 5rem;
      flex: 1 1; }
  .About a {
    width: 20%;
    height: 100%; }
  .About-img {
    max-width: 20vw;
    object-fit: contain; }

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0); }
  50% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes spin {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0); }
  50% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.Work {
  min-height: 100vh;
  background-color: var(--Light);
  color: var(--Dark);
  grid-column: 1 / 3;
  display: grid;
  grid-template-columns: 1fr 3.25fr;
  padding: 5rem 0; }
  .Work-content {
    width: 65vw;
    -webkit-transition: all .25s;
    transition: all .25s;
    grid-column: 2 / 3; }
  .Work-item {
    -webkit-transition: all .25s;
    transition: all .25s; }
    .Work-item-name {
      cursor: pointer;
      position: relative;
      font-family: 'Exo', sans-serif;
      font-size: var(--Medium-text); }
      .Work-item-name::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0;
        height: .5rem;
        border-radius: 20rem;
        -webkit-transition: all .25s ease-out;
        transition: all .25s ease-out; }
      .Work-item-name:hover::after {
        width: 50%;
        background-color: var(--Sand);
        -webkit-transform-origin: center;
                transform-origin: center; }
    .Work-item:not(:last-child) {
      margin-bottom: 3rem; }
  .Work-paragraph {
    -webkit-columns: 20rem 2;
       -moz-columns: 20rem 2;
            columns: 20rem 2;
    grid-column-gap: 5rem;
    -webkit-column-gap: 5rem;
       -moz-column-gap: 5rem;
            column-gap: 5rem;
    font-size: var(--Small-text);
    line-height: 3.3rem;
    width: 100%;
    margin: 2rem 0 4rem 0; }
    .Work-paragraph a:hover {
      color: var(--Sand); }

.ItemDropDown {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  -webkit-animation: FadeIn .5s ease-out;
          animation: FadeIn .5s ease-out; }
  .ItemDropDown-text {
    display: flex;
    margin: 2rem 0 4rem 0;
    font-size: var(--Small-text);
    line-height: 2.6rem; }
  .ItemDropDown-description {
    width: 50%;
    margin-right: 2rem; }
  .ItemDropDown a {
    width: 100%; }
  .ItemDropDown .ItemImg {
    width: 100%;
    margin: 1rem 0; }

@-webkit-keyframes FadeIn {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-1rem);
            transform: translateY(-1rem); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@keyframes FadeIn {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-1rem);
            transform: translateY(-1rem); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

.Contact {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  grid-column: 2 / 3; }
  .Contact-title {
    font-family: 'Exo', sans-serif;
    text-transform: uppercase;
    font-size: var(--Medium-text);
    margin-bottom: 5rem; }
  .Contact-content {
    width: 65vw;
    display: flex; }
  .Contact-link:not(:last-child) {
    margin-right: 22rem; }

#instagram {
  width: 8rem;
  fill: var(--Blood);
  -webkit-transition: all .2s ease-out;
  transition: all .2s ease-out; }
  #instagram:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1); }

#linkedin {
  width: 8rem;
  fill: var(--Sand);
  -webkit-transition: all .2s ease-out;
  transition: all .2s ease-out; }
  #linkedin:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1); }

#mail {
  width: 8rem;
  fill: var(--Sky);
  -webkit-transition: all .2s ease-out;
  transition: all .2s ease-out; }
  #mail:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1); }

@media all and (max-width: 1400px) {
  .About-paragraph span {
    background: transparent;
    color: var(--Blood); } }

@media all and (max-width: 1000px) {
  * {
    --Large-text: 8rem;
    --Medium-text: 6rem; }
  .About-DropDown {
    flex-direction: column;
    justify-content: flex-start; }
    .About-DropDown > * {
      margin-bottom: 3rem; }
  .About-img {
    max-width: 50vw; }
  .Contact-link:not(:last-child) {
    margin-right: 16rem; } }

@media all and (max-width: 800px) {
  * {
    --Large-text: 7rem;
    --Medium-text: 4rem; }
  .About-img {
    width: 50vw; }
  .Contact-link:not(:last-child) {
    margin-right: 8rem; } }

@media all and (max-width: 700px) {
  .App {
    width: 100vw;
    justify-items: center; }
    .App section {
      padding: 4rem; }
  .Nav {
    display: none; }
  .About {
    grid-column: 1 / 3;
    width: 100%;
    padding: 0; }
    .About-DropDown {
      width: 100%; }
  .Work {
    width: 100vw; }
    .Work-content {
      grid-column: 1 / 3;
      width: 100%; }
  .Contact {
    grid-column: 1 / 3;
    justify-self: start; }
    .Contact-title {
      margin-bottom: 4rem; }
  #instagram {
    width: 7rem; }
  #linkedin {
    width: 7rem; }
  #mail {
    width: 7rem; } }

@media all and (max-width: 600px) {
  * {
    --Large-text: 6rem;
    --Medium-text: 3rem; }
  -Contact-title {
    margin-bottom: 10rem; }
  #instagram {
    width: 4rem; }
  #linkedin {
    width: 4rem; }
  #mail {
    width: 4rem; } }

@media all and (max-width: 500px) {
  .ItemDropDown-text {
    flex-direction: column; }
  .ItemDropDown-description {
    width: 100%; }
    .ItemDropDown-description:first-child {
      margin-bottom: 2rem; } }

@media (pointer: coarse) {
  .Separator {
    background-attachment: scroll; }
  .About-img {
    -webkit-transform: none;
            transform: none; } }

