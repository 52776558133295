.About {
    display: flex;
    justify-content: flex-start;
    min-height: 100vh;
    width: 65vw;
    padding: 7rem 5.2vw 7rem 0;

    grid-column: 2 / 3;

    &-content {
        display: flex;
        flex-direction: column;
    

        font-size: var(--Large-text);
    }

    &-paragraph {
        font-family: 'Exo', sans-serif;
        text-transform: uppercase;
        line-height: var(--Large-text);
        margin-bottom: 3rem;

        span {
            background: linear-gradient(to left, var(--Blood), var(--Sand), var(--Sky));
            background-size: cover;
            -webkit-animation: Swirl 3s ease infinite;
            animation: Swirl 3s ease infinite;
            -webkit-background-clip: text;
            background-clip: text;
            color: transparent;
            font-family: inherit;
        }

        a {
            font-family: inherit;
        }


        & a:hover {
            color: var(--Sand);
        }
    }

    &-Button {
        height: 5rem;
        width: 5rem;
        fill: var(--Neutral);
        margin-bottom: 3rem;

        &:hover {
            animation: spin 1s linear infinite forwards;
        }
    }

    &-DropDown {
        margin-bottom :5rem;
        width: 65vw;
        font-size: var(--Small-text);
        line-height: 2.6rem;
        display: flex;
        justify-content: space-between;

        animation: FadeIn .2s ease-out;
        
        & > * {
            margin-right: 5rem;
            flex: 1;
        }
    }

    & a {
        width: 20%;
        height: 100%;
    }

    &-img {
        // transform: rotate(180deg);
        max-width: 20vw;
        object-fit: contain;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0);
    }

    50% {
        transform: rotate(180deg);
    }

    100% {
        transform: rotate(360deg);
    }
}