.App {
  min-height: 100vh;

  color: var(--Light);

  display: grid;
  grid-template-columns: 1fr 3.25fr;

  section {
    padding: 7rem 0;
  }

  img {
    border-radius: 1rem;
  }
}

.Separator {
  width: 100%;
  height: 50rem;
  grid-column: 1 / 3;

  background-size: cover;
  background-color:transparent;
  background-attachment: fixed;
  background-position: center;
  overflow: hidden;

  &:last-child {
    background-position: top;
    height: 65rem;
  }
}

.Nav {
  position: sticky;
  top: 7rem;
  left: 5.2vw;
  height: 85vh;
  width: 18vw;

  grid-column: 1 / 2;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  color: var(--Neutral);
  font-size: 3rem;
  
  transition: all .2s ease-out;

  &-bar {
    display: flex;
    flex-direction: column;
    width: 200px;
  }

  &-logo {
    width:7rem;
    fill: var(--Neutral);
  }

  & > * {
    margin-bottom: 1rem;
  }
}

a {
  text-decoration: none;
  color: inherit;
  font-family: 'Asap', sans-serif;
}