@media all and (max-width: 1400px) {
    .About {
        &-paragraph {
            span {
                background: transparent;
                color: var(--Blood);
            }
        }
    }
}

@media all and (max-width: 1000px) {
    * {
        --Large-text: 8rem;
        --Medium-text: 6rem;
    }

    .About  {

        &-DropDown {
            flex-direction: column;
            justify-content: flex-start;
    
            & > * {
                margin-bottom: 3rem;
            }
        }

        &-img {
            max-width: 50vw;        }
    }

    .Contact-link:not(:last-child) {
        margin-right: 16rem;
    }

}

@media all and (max-width: 800px) {
    * {
        --Large-text: 7rem;
        --Medium-text: 4rem;
    }

    .About {
        &-img {
            width: 50vw;
        }
    }

    .Contact-link:not(:last-child) {
        margin-right: 8rem;
    }
}

@media all and (max-width: 700px) {

    .App {
        width :100vw;
        justify-items: center;
        
        section {
            padding: 4rem;
        }
    }

    .Nav {
        display: none;
    }

    .About {
        grid-column: 1 / 3;
        width: 100%;
        padding: 0;

        &-DropDown {
            width: 100%;
        }

    }

    .Work {
        width: 100vw; 

        &-content {
            grid-column: 1 / 3;
            width: 100%;

        }
    }

    .Contact {
        grid-column: 1 / 3;
        justify-self: start;

        &-title {
            margin-bottom: 4rem;
        }
    }

    #instagram {
        width: 7rem;
    }

    #linkedin {
        width :7rem;
    }

    #mail {
        width: 7rem;
    }
}

@media all and (max-width: 600px) {
    * {
        --Large-text: 6rem;
        --Medium-text: 3rem;
    }

    &-Contact-title {
        margin-bottom: 10rem;
    }

    #instagram {
        width: 4rem;
    }

    #linkedin {
        width :4rem;
    }

    #mail {
        width: 4rem;
    }

}

@media all and (max-width: 500px) {
    .ItemDropDown {
        &-text {
            flex-direction: column;
        }
        
        &-description {
            width: 100%;

            &:first-child {
                margin-bottom: 2rem;
            }
        }
    }
}

@media (pointer: coarse) {
    .Separator {
        background-attachment: scroll;
    }

    .About-img {
        transform: none;
    }
}